import React from 'react';
import QueryString from 'query-string';
import { sanitizeUrlString } from '../helpers/utils';

export default function Success() {
  React.useEffect(() => {
    const qs = QueryString.parse(location.search);
    const url = qs.targetUrl || '';
    if(sanitizeUrlString(url)) {
      window.top.location.href  = url
    } else {
      window.top.location.href  = '/'
    }
  }, []);

  return <></>;
}
